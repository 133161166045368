@import "../../../assets/scss/icons.scss";
@import "../../../assets/scss/bootstrap.scss";
@import "../../../assets/scss/app.scss";
@import "../../../assets/scss/new_varaibles";
.img__container {
    width: 30%;
    margin-right: 1em !important;
}

.img__container img {
    width: 100%;
}

.card-module:hover {
    cursor: pointer;
    transition: all 0.6s ease-in-out;
    color: white;
    background: $report-success;
}

.module {
    width: 80% !important;
    margin: 0 auto !important
}

.cat {
    border-radius: 15px !important;
    color: $black;
    cursor: pointer;
}

.cat label {
    line-height: 3.0em;
    cursor: pointer
}

.cat label span {
    text-align: center;
    padding: 3px 0;
    display: block;
}

.cat label AvCheckbox {
    position: absolute;
    display: none;
    color: #fff !important;
}

.cat label AvCheckbox+span {
    color: #fff;
}

.cat AvCheckbox:checked+span {
    color: #ffffff;
    text-shadow: 0 0 6px rgba(0, 0, 0, 0.8);
}

.module-container {
    background: transparent !important;
}

.form-control {
    background: transparent !important;
}

.checkbox {
    border: 2px solid #ebebeb;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    padding: 2em;
}

.activate-color {
    background: $report-success !important;
    color: white !important;
}