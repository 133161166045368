@import "./icons.scss";
// Dark Theme
// @import "./bootstrap-dark.scss";
// @import "./app-dark.scss";
// Light Theme
@import "./bootstrap.scss";
@import "./app.scss";
@import "./_new_varaibles.scss";
// RTL Mode
// @import "./rtl/bootstrap-rtl";
// @import "./rtl/components-rtl";
// @import "./rtl/float-rtl";
// @import "./rtl/general-rtl";
// @import "./rtl/pages-rtl";
// @import "./rtl/plugins-rtl";
// @import "./rtl/spacing-rtl";
// @import "./rtl/structure-rtl";
// @import "./rtl/text-rtl";
body {
  * {
    outline: none;
  }
}

#sidebar-menu {
  ul {
    li {
      a {
        i {
          min-width: 2rem;
        }
      }
    }
  }
}

.fa,
.fas {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}

[class*=" ti-"],
[class^="ti-"] {
  font-family: themify;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.noti-icon .badge {
  left: 23px;
}

.dataTables_filter,
.dataTables_paginate {
  float: right;
}

.rdw-editor-main {
  border: 1px solid $gray-300;
  height: 239px;
}

.dz-message {
  text-align: center;
  margin: 2em 0;
}

// kanban board
.react-kanban-column {
  height: 100%;
  min-height: 28px;
  display: inline-block;
  padding: 15px;
  border-radius: 2px;
  background-color: rgb(238, 238, 238);
  margin: 5px;
  vertical-align: top;
}

.fcYNFc,
.sc-AxmLO.gmtmqV {
  background-color: $card-bg !important;
  margin: 0 20px 0 0 !important;
}

.task-box {
  border: 1px solid $border-color;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.ReactModal__Overlay {
  z-index: 1001 !important;
}

.chat-conversation .right .conversation-list {
  margin-right: 15px;
}

.external-event {
  &:hover {
    cursor: pointer;
  }
}

a:hover {
  cursor: pointer;
}

.rating-container {
  background-color: transparent !important;
}

.input-group-append {
  z-index: 0;
}

.input-color {
  color: $input-color !important;
}

.sketch-picker {
  position: absolute;
  z-index: 1;
}

.rangeslider__fill {
  background-color: $primary !important;
}

.flatpickr-months .flatpickr-month {
  background: $primary !important;
}

.flatpickr-weekdays {
  background: $primary !important;
}

span.flatpickr-weekday {
  background: $primary !important;
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
  background: $primary !important;
}

span.flatpickr-weekday {
  color: $white !important;
}

.flatpickr-day.selected {
  background: $primary !important;
}

//File-manager
.file-manager {
  .table td {
    padding: 0.35rem;
  }
}

//Crypto enteries
.bs-select select {
  width: auto !important;
}

//Authenication carousel
.slider_css {
  .slide {
    background: none !important;
  }
  .carousel-status,
  .control-next,
  .control-prev {
    display: none !important;
  }
}

.carousel {
  .control-dots {
    margin: -5px 0px;
  }
  .dot {
    background: #556ee6 !important;
    width: 9px !important;
    height: 9px !important;
  }
}

// calendar modal
.close {
  background: transparent;
  border: 0;
  font-size: 10px;
  padding: 1.35rem 1.25rem;
  background: transparent escape-svg($btn-close-bg-dark) center /
    $btn-close-width auto no-repeat;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.5;
  width: 1em;
  height: 1em;
  z-index: 2;
  span {
    display: none;
  }
}

.wizard .actions {
  position: relative !important;
  display: block !important;
  text-align: right !important;
  width: 100% !important;
}

.wizard .actions > ul {
  display: block !important;
  text-align: right !important;
  padding-left: 0 !important;
}

.wizard .actions > ul > li {
  display: inline-block !important;
  margin: 0 0.5em !important;
}

//Dropdown
.dropup .dropdown-menu {
  top: auto !important;
  bottom: 100% !important;
  transform: translate3d(5px, 5px, 0px) !important;
}
.notification-menu-width {
  width: 375px !important;
}

//form editor
.rdw-editor-toolbar,
.rdw-editor-main {
  border: 1px solid #ccc !important;
}

//Calendar
.app-calendar .btn {
  text-transform: capitalize;
}

.dropzone:focus {
  outline: none !important;
}

//dropdown
.dropdown-menu.dropdown-menu-right {
  right: 0 !important;
  left: auto !important;
}

.card-columns {
  column-count: 3;
  grid-column-gap: 1.25rem;
  -webkit-column-gap: 1.25rem;
  grid-column-gap: 24px;
  column-gap: 24px;
  orphans: 1;
  widows: 1;
}

.ion-ios-checkmark-circle-outline {
  font-size: 3.5rem;
  line-height: 56px;
}

label {
  margin-bottom: 0.5rem;
}

.DraftEditor-editorContainer {
  margin-left: 14px !important;
}

.wizard .steps .nav-link {
  background-color: $background;
  color: #626ed4;
}

.wizard .steps .nav-link .number {
  border-color: $background;
}

.login-side-container {
  background: $background;
  height: 98vh;
}

.login-content-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.property-content-container {
  width: 90%;
  margin: 0 auto;
}

.login-header,
.login-btn {
  background: $background;
}

.login-btn {
  color: white;
}

.login-btn:hover,
.login-btn:focus {
  color: white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  transition: 0.3s;
  background: $background;
  transform: translateY(-0.25em);
}

.remember {
  color: $background;
}

.remember:hover {
  color: $background;
  transform: translateY(-0.25em);
}

.login-img-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100% !important;
}

.login-img-container img {
  width: 50%;
  margin: 0 auto;
}

// .account-pages .logo-admin {
//     border-bottom: 4px solid $background;
// }
.welcome {
  border-radius: $border-radius;
  background: $background;
  color: $white;
}

.bd-rds {
  border-radius: $border-radius;
}

.TenantHeader {
  background-color: $background;
  padding: 1em;
  color: white;
}

.bd-rds-header {
  border-top-left-radius: $header-Border-raddius-top-left;
  border-top-right-radius: $header-Border-raddius-top-right;
}

.newTenantSubmitBtn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.welcome {
  border-radius: $border-radius;
  background: $background;
  color: $white;
}

.bd-rds {
  border-radius: $border-radius;
}

::-webkit-input-placeholder {
  /* Edge */
  color: grey;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: grey;
}

::placeholder {
  color: grey;
}

.wizard .steps .nav-link {
  background: $background;
  color: $white;
  border-radius: $border-radius;
}

.wizard > .steps .current a,
.wizard > .steps .current a:active,
.wizard > .steps .current a:hover {
  background: $secondary_bg;
  border-radius: $border-radius;
}

.wizard .steps .nav-link .number {
  border-color: $white;
}

//!! Table Header Color
.sorting_desc {
  // background: $background !important;
  // color: white !important;
  text-align: center !important;
}

//Active pagination color
.active .page-link {
  background: $background !important;
}

.share-Container {
  border: 1px dashed;
  min-height: 60vh;
  border-radius: $border-radius;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.share-icon {
  color: #cfcfcf;
}

.container-content {
  background: white;
  border-radius: $border-radius;
}

.module-content-container {
  height: 90vh;
  justify-content: center;
  display: flex;
}

.module-container {
  width: 70%;
  margin: 0 auto;
}

ul li {
  list-style-type: none;
  padding: 0.5em 0;
}

.continue-register {
  justify-content: center;
  align-items: center;
  display: flex;
}
.package_subscription_Container {
  padding-top: 100px;
}
.package_subscription {
  margin: auto;
  width: 300px;
  border-radius: 10px;
  padding: 5px 5px 0.5px 5px;
}
.package_subscription_card {
  border: 0.5px rgb(29, 29, 29);
  height: 690px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
}
.package_subscription_title {
  display: flex;
  justify-content: center;
  height: 40px;
}
.package_subscription_text {
  padding-top: 4px;
  font-size: 16px;
  color: #fff;
  font-weight: bold 200;
}
.package_subscription_features {
  height: 500px;
  // border: 3px red solid;
}
.faspro_bg {
  background-color: #26a958;
}
// .faspro_bg {
//   background-color: #26a958;
// }
.module-container-page {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.bg-table-header,
.btn-view-detail {
  // background: $background;
  color: $white;
}

.service-card-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.login-container {
  width: 40%;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .login-container {
    width: 90%;
    margin: 0 auto;
  }
}

.bg-img {
  background-image: url("../../assets/images/components-img/utilities.jpg");
  background-color: #cccccc;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 300px;
  border-radius: 15px;
  position: relative;
}

.img-opacity {
  background: black;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  opacity: 0.6;
  border-radius: 15px;
}

.bg-card {
  background: $background;
}

.report-danger {
  background: $report-danger;
}

.report-warning {
  background: $report-warning;
}

.report-primary {
  background: $report-primary;
}

.report-success {
  background: $report-success;
}
.button___style {
  width: 90px;
  height: 30px;
  border-radius: 5px;
  background: $report-success;
  text-align: center;
}
.cards-img {
  width: 90%;
  margin: 0 auto;
}

.cards-img img {
  width: 100%;
}

.login-card-hover:hover {
  background: $report-success;
  transition: all 0.5s ease;
}

.login-card-hover:hover h4 {
  color: $white !important;
}

.logo-sm {
  width: 100% !important;
}

.logo-sm img {
  width: 45px !important;
  margin-left: -15px !important;
}

.logo-lg {
  width: 100% !important;
}

.logo-lg img {
  width: 200px !important;
  margin-left: -105px !important;
}

.residential-clr {
  background-color: $report-success;
}

.commercial-clr {
  background-color: $commercial-clr;
}

.phone_number {
  border: none !important;
  border: 0px solid white !important;
}

::-webkit-input-placeholder {
  color: #dbdbdb !important;
}

:-ms-input-placeholder {
  color: #dbdbdb !important;
}

::placeholder {
  color: #dbdbdb !important;
}

.property_features {
  width: 800px;
}

.form-control {
  background: #f3f3f3a2 !important;
  border: none;
  padding: 0.8em;
  color: #0a8a6b !important;
}

.Input__form_2 {
  background: #ffffff !important;

  padding: 0.8em;
  color: #0a8a6b !important;
}
// .AvCheck {
//   background: rgb(77, 77, 243);
// }
.property-image {
  max-width: 350px;
  height: 300px;
}
.property-image img {
  width: 100%;
  height: 100%;
  border-radius: 15px;
}

.property-img {
  width: 350px;
}

.property-img img {
  width: 100%;
  border-radius: 15px;
}

.btn {
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
}
.property_report_img {
}
.property_report_img > img {
  border-radius: 8px;
}
.success-message {
  width: 100% !important;
  background-color: #d4edda;
  border: 1px solid #c3e6cb;
  color: #155724;
  padding: 10px 20px;
  margin-bottom: 2em;
  border-radius: 4px;
  text-align: center;
}

/*printing button*/
exlude the button when priting .print-button {
  display: block;
}
@media print {
  .print-button {
    display: none;
  }
}

/*view Tickets*/

.tickets_pictures {
  width: 100%;
  height: 350px;
  object-fit: cover;
}

/* Preview file css */

.file-previews-doc {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}

.file-preview-doc {
  display: flex;
  align-items: center;
  margin: 10px;
  padding: 10px;
  background-color: #f5f5f5;
  border-radius: 5px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.file-icon-doc {
  font-size: 28px;
  margin-right: 15px;
  color: #26a958;
}

.file-details-doc {
  display: flex;
  flex-direction: column;
}

.file-name-doc {
  font-size: 16px;
  font-weight: bold;
}

.file-size-doc {
  font-size: 14px;
  color: #666;
}

.btn-width {
  width: 120px !important;
}
.bookableImages-sizes {
  width: 200px !important;
  height: 200px !important;
}

/* Media query for responsiveness */
@media (max-width: 576px) {
  .file-preview-doc {
    width: 100%;
  }
}
