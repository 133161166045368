.form-control_2 {
  background: #f3f3f3a2 !important;
  border: none;
  padding: 0.8em;
  color: #0a8a6b !important;
}

.Input {
  background: #ffffff !important;

  padding: 0.8em;
  color: #0a8a6b !important;
}
