.form-wrapper {
  width: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  // border: 3px #007bff solid;
}

.form-container h1 {
  font-size: 36px;
  margin-bottom: 30px;
  // border: red solid 4px;
}

.step {
  display: none;
}

.step.active {
  display: block;
}

.step input {
  width: 100%;
  margin: 20px 0;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.button-container {
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  margin-bottom: 5px;
}

.prev-button,
.next-button,
.submit-button {
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  color: #fff;
  font-size: 18px;
  cursor: pointer;
}

.prev-button {
  background-color: #bbb;
}

.next-button {
  background-color: #007bff;
}

.submit-button {
  background-color: #28a745;
}

.progress-container {
  display: flex;
  justify-content: space-evenly;
  width: 150px;
  margin-top: 20px;
}

.progress-step {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #ddd;
  color: #fff;
  font-size: 24px;
}

.progress-step.completed {
  background-color: #28a745;
}
