// .logo {
//   // background: blue !important;
// }

// .logo-dark,
// .logo-light {
//   // background: rgb(0, 255, 221) !important;
// }

// .navbar-brand-box {
// }

.success-message {
  background: #ffff !important;
  color: #1da176 !important;
  font-size: 18px !important;
  width: 28vw !important;
  padding: 0.8em !important;
  font-family: "Times New Roman", Times, serif;
}

.warning-message {
  background: #ffff !important;
  color: #f1b44c !important;
  font-size: 18px;
  width: 28vw;
  padding: 0.8em;
  font-family: "Times New Roman", Times, serif;
}

.danger-message {
  background: #ffff !important;
  color: #e54657 !important;
  font-size: 18px;
  width: 28vw;
  padding: 0.8em;
  font-family: "Times New Roman", Times, serif;
}

.notification__belt {
  position: relative;
}

.notification__belt__number {
  position: absolute;
  top: 0;
}

.container-form {
  width: 800px;
}
.wrapper-form {
  width: 95%;
}

//dropzone styling
.drop-zone {
  position: relative;
  border: 2px dashed #c0c0c0;
  border-radius: 5px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
}

/* Styles for the drop zone label */
.drop-zone-label {
  display: block;
  margin-bottom: 10px;
}

/* Styles for the icon inside the drop zone label */
.drop-zone-label i {
  font-size: 24px;
  margin-bottom: 5px;
}

/* Styles for the text inside the drop zone label */
.drop-zone-label .drop-zone-text {
  font-size: 16px;
  font-weight: bold;
}

/* Hide the default file input */
.drop-zone-input {
  // display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}
