.talk_____logo {
  width: 200px;
  margin-left: 0px;
}
.Talk-text {
  color: #fffefe;
  text-align: justify;
}

.card__bg__color {
  background: #26a958;
  border-radius: 5px;
}

.module_____container {
  background: #ffffff;
  border-radius: 10px !important;
  height: 300px;
}
.module_____img {
  width: 30%;
  margin-right: 1em !important;
}
.module_____img img {
  width: 100%;
}
// .module_____content {
// }
